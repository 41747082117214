@use '../vars/breakpoints';
@use '../vars/fonts';
@use '../mixins';
@use '../utils';

// Normal
@font-face {
    font-family: Butler;
    font-style: normal;
    font-weight: 400;
    src: url('#{fonts.$fontPath}/Butler/Butler.woff2') format('woff2'),
        url('#{fonts.$fontPath}/Butler/Butler.woff') format('woff');
}

// Set up Raleway for modern browsers
@supports (font-variation-settings: normal) {
    @font-face {
        font-family: Raleway;
        font-style: normal;
        font-weight: 300 700;
        font-display: swap;
        src: url('#{fonts.$fontPath}/Raleway/Raleway-VariableFont_wght.woff2')
            format('woff2-variations');
    }
}

// Set up Raleway for older browsers
@supports not (font-variation-settings: normal) {
    // Light
    @font-face {
        font-family: Raleway;
        font-style: normal;
        font-weight: 300;
        src: url('#{fonts.$fontPath}/Raleway/Raleway-Light.woff2') format('woff2'),
            url('#{fonts.$fontPath}/Raleway/Raleway-Light.woff') format('woff');
    }

    // Normal / Regular
    @font-face {
        font-family: Raleway;
        font-style: normal;
        font-weight: 400;
        src: url('#{fonts.$fontPath}/Raleway/Raleway-Regular.woff2') format('woff2'),
            url('#{fonts.$fontPath}/Raleway/Raleway-Regular.woff') format('woff');
    }

    // Medium
    // @font-face {
    //   font-family: 'Raleway';
    //   src: url('#{fonts.$fontPath}/Raleway/Raleway-Medium.woff2') format('woff2'),
    //         url('#{fonts.$fontPath}/Raleway/Raleway-Medium.woff') format('woff');
    //   font-weight: 500;
    //   font-style: normal;
    // }

    // SemiBold
    @font-face {
        font-family: Raleway;
        font-style: normal;
        font-weight: 600;
        src: url('#{fonts.$fontPath}/Raleway/Raleway-SemiBold.woff2') format('woff2'),
            url('#{fonts.$fontPath}/Raleway/Raleway-SemiBold.woff') format('woff');
    }

    // Bold
    @font-face {
        font-family: Raleway;
        font-style: normal;
        font-weight: 700;
        src: url('#{fonts.$fontPath}/Raleway/Raleway-Bold.woff2') format('woff2'),
            url('#{fonts.$fontPath}/Raleway/Raleway-Bold.woff') format('woff');
    }
}

// Light
@font-face {
    font-family: Rawline;
    font-style: normal;
    font-weight: 300;
    src: url('#{fonts.$fontPath}/Rawline/rawline-300.woff') format('woff');
}

// Normal / Regular
@font-face {
    font-family: Rawline;
    font-style: normal;
    font-weight: 400;
    src: url('#{fonts.$fontPath}/Rawline/rawline-400.woff') format('woff');
}

// SemiBold
@font-face {
    font-family: Rawline;
    font-style: normal;
    font-weight: 600;
    src: url('#{fonts.$fontPath}/Rawline/rawline-600.woff') format('woff');
}

// Bold
@font-face {
    font-family: Rawline;
    font-style: normal;
    font-weight: 700;
    src: url('#{fonts.$fontPath}/Rawline/rawline-700.woff') format('woff');
}

html * {
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
}

html {
    font-size: 16px;
}

body {
    font-family: fonts.$sans;
    font-size: 0.875rem;
    font-weight: fonts.$fw-normal;
    line-height: fonts.$lh-500;
}

/**
* Font sizes for headings are defined using custom properties so
* that we can adjust at different breakpoints. An h1 on a mobile
* device should be smaller than on a desktop screen.
*/
:root {
    --h1: #{fonts.$fs-700};
    --h2: #{fonts.$fs-600};
    --h3: #{fonts.$fs-500};
    --h4: #{fonts.$fs-400};
    --h5: #{fonts.$fs-400};
}

@include mixins.screen('md') {
    :root {
        --h1: #{fonts.$fs-800};
        --h2: #{fonts.$fs-700};
        --h3: #{fonts.$fs-600};
        --h4: #{fonts.$fs-500};
        --h5: #{fonts.$fs-400};
    }
}

@mixin heading-base {
    font-style: normal;
    line-height: fonts.$lh-500;
}

$heading-levels: 1 2 3 4 5;

/**
* Generates utility classes for headings
* .h<size>, .<breakpoint?:>h<size>
* .h3, .md:h2
*/
@each $size in $heading-levels {
    .h#{$size} {
        @include heading-base;

        font-size: var(--h#{$size});
    }

    @each $name, $breakpoint in breakpoints.$breakpoints {
        @media (min-width: $breakpoint) {
            .#{$name}\:h#{$size} {
                @include heading-base;

                font-size: var(--h#{$size});
            }
        }
    }
}

/**
* Generates utility classes for font sizes
* .fs-<size>, .<breakpoint?:>fs-<size>
* .fs-200, .md:fs-200
*/
@each $size, $value in fonts.$font-size {
    .fs-#{$size} {
        font-size: $value;
    }

    @each $name, $breakpoint in breakpoints.$breakpoints {
        @media (min-width: $breakpoint) {
            .#{$name}\:fs-#{$size} {
                font-size: $value;
            }
        }
    }
}

/**
* Generates utility classes for font weights
* .fw-<weight>, .<breakpoint?:>fw-<weight>
* .fw-light, .md:fw-bold
*/
@each $weight, $value in fonts.$font-weight {
    .fw-#{$weight} {
        font-weight: $value;
    }

    @each $name, $breakpoint in breakpoints.$breakpoints {
        @media (min-width: $breakpoint) {
            .#{$name}\:fw-#{$weight} {
                font-weight: $value;
            }
        }
    }
}

/**
* Generates utility classes for line heights
* .lh-<size>, .<breakpoint?:>lh-<size>
* .lh-200, .md:lh-200
*/
@each $size, $value in fonts.$line-height {
    .lh-#{$size} {
        line-height: $value;
    }

    @each $name, $breakpoint in breakpoints.$breakpoints {
        @media (min-width: $breakpoint) {
            .#{$name}\:lh-#{$size} {
                line-height: $value;
            }
        }
    }
}

.font-sans {
    font-family: fonts.$sans;
}

.font-serif {
    font-family: fonts.$serif;
}

.font-numbers {
    font-family: fonts.$numbers;
}

.content {
    @each $size in $heading-levels {
        .h#{$size},
        h#{$size} {
            @include heading-base;

            margin-bottom: 0.5em;
            font-size: var(--h#{$size});

            &:not(:first-child) {
                margin-top: 1.1em;
            }
        }
    }

    p {
        line-height: fonts.$lh-700;
    }

    ul,
    ol,
    p {
        & + p {
            margin-top: 1rem;
        }
    }

    ul,
    ol {
        padding-left: 2rem;
        margin-top: 1rem;

        li + li {
            margin-top: 1rem;
        }
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }
}

.text-center {
    text-align: center;
}

.text-link {
    color: utils.color('royal', 500);
}
